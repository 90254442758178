<template>
  <div class="container">
    <div class="p-at">
      <p class="p-at__title headline-4">
        Активные стандартные тарифы отсутствуют
      </p>
      <p class="p-at__subtitle title-5">
        Свяжитесь с нами для формирования персонального предложения
      </p>
      <div class="p-at__actions d-flex">
        <d-btn
          class="p-at__connect"
          color="primary"
          @click="showDialogCallPartner"
        >
          Подключить
        </d-btn>
        <d-btn
          class="p-at__consult"
          color="white-black"
          @click="showDialogCallPartner"
        >
          Консультация
        </d-btn>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const ctx = useNuxtApp()
const showDialogCallPartner = () => {
  ctx.$event('callDialogPartner', true)
}
</script>

<style scoped lang="scss">
.p-at {
  background: color(gray-light-3);
  padding: 40px;
  border-radius: 16px;
  @media (max-width: getBreakpoint(tablet)) {
    padding: 24px;
  }
  @media (max-width: getBreakpoint(mobile-lg)) {
    padding: 16px;
  }

  &__title {
    font-size: 34px;
    margin-bottom: 4px;
    @media (max-width: getBreakpoint(desktop)) {
      font-size: 24px;
      margin-bottom: 8px;
    }
    @media (max-width: getBreakpoint(tablet)) {
      font-size: 22px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      font-size: 21px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      font-size: 20px;
    }
  }
  &__subtitle {
    margin-bottom: 24px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 16px;
    }
  }
  &__actions {
    @media (max-width: getBreakpoint(tablet)) {
      flex-direction: column;
    }
  }
  &__connect {
    background: var(--btn) !important;
    &:hover {
      background: var(--btn);
    }
  }
  &__consult {
    padding: unset !important;
    background: unset !important;
    border: unset !important;
    margin: auto 16px;
    @media (max-width: getBreakpoint(tablet)) {
      margin: 16px auto 0;
    }
  }
}
</style>
